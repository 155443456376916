/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import React from 'react';  
import PlayerCollage from './PlayerCollage';

// @mui material components
import Card from "@mui/material/Card";

import Parser from 'html-react-parser';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


//import Modal from 'react-bootstrap/Modal';

// Data
//import membersTableData from "layouts/groups/data/membersTableData";

import SoftAvatar from "components/SoftAvatar";

// Images
import team2 from "assets/images/team-2.jpg";



export default class Miplain extends React.Component {  
  constructor(props) {
    super(props);
    this.setState({loggedIn: '1'});
    this.setState({showModal: false});
    this.setState({viewstatus: 'list'});

    this.state = {
        series_name: 'Champions League',
        team_name: 'Dream Team',
        players: [
          { player_name: 'Player 1', player_img_url: 'https://via.placeholder.com/100' },
          { player_name: 'Player 1', player_img_url: 'https://via.placeholder.com/100' },
          { player_name: 'Player 1', player_img_url: 'https://via.placeholder.com/100' },
          { player_name: 'Player 1', player_img_url: 'https://via.placeholder.com/100' },
          { player_name: 'Player 1', player_img_url: 'https://via.placeholder.com/100' },
          { player_name: 'Player 1', player_img_url: 'https://via.placeholder.com/100' },
          { player_name: 'Player 1', player_img_url: 'https://via.placeholder.com/100' },
          { player_name: 'Player 1', player_img_url: 'https://via.placeholder.com/100' },
          { player_name: 'Player 2', player_img_url: 'https://via.placeholder.com/100' },
          { player_name: 'Player 3', player_img_url: 'https://via.placeholder.com/100' },
          // Add more players as needed
        ]
      }
  }
 

  componentDidMount() { 

    

      

  }  
  
  
  
  
  

  render() {  

    return (  
      <SoftBox>
      
      <PlayerCollage
        seriesName={this.state.series_name}
        teamName={this.state.team_name}
        players={this.state.players}
      />
      
      
    </SoftBox>
    )  
  }  
}  
